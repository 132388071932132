//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "SearchDrawer",
  props: {
    title: {
      type: String,
      default: "筛选",
    },
  },
  data: () => ({
    open: false,
  }),
  methods: {
    beforeMount(val) {
      this.open = val;
    },
    handleSearch() {
      this.open = false;
      this.$emit("search");
    },
  },
};
