//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import MixinTeamOptions from "@/mixins/teamOptions";
import SearchDrawer from "@/components/SearchDrawer";
import ChartPie from "@/components/Charts/Pie";
import ChartColumn from "@/components/Charts/Column";
import ProcessDataDialog from "@/components/Dialogs/processDataDialog";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
export default {
  name: "Screenshots",
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    ChartMore: () => import("@/components/keyWordChartMore"),
    SearchDrawer,
    ChartPie,
    ChartColumn,
    ProcessDataDialog,
  },
  mixins: [MixinTeamOptions, MixinWebrtc],
  data() {
    return {
      functionName: "定时截图",
      NeedDataChannel: true,
      focusFormNameText: "", // 窗口名关键词搜索
      appDesText: "", //软件名关键词搜索
      pnameText: "", //进程名搜索关键词
      keywords: null, //关键词
      // searchType: 1, //搜索类型
      ds: null, //开始时间
      de: null, //结束时间
      listData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [],
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
      screenshotNowLoading: false,
      timer: null,
      dialogVisible: false,
      screenshotData: null,
      screenshotList: [],
      dateType: 1, //时间查询类型
      dateRange2PickerOptions: {
        disabledDate: (v) => v <= new Date(this.DateRange[0]),
      },
      chartLoading: false,
      chartData: null,
      dialogs: {
        software: {
          open: false,
          options: {},
          title: "",
        },
        title: {
          open: false,
          options: {},
          title: "",
        },
      },
      isTimeScreenshot: true,
      moreChartOption: {
        title: null,
        tData: [],
        mName: "screChartMore",
        type: 1, //1为停留时长，2为次数
        chartType: null, //点击的图表类型
        dataTotal: 0,
      },
      keyWordTags: {
        appDesTag: [],
        focusFormNameTag: [],
      },
      selKeyWordTags: {
        appDesTagSel: [],
        focusFormNameTagSel: [],
      },
    };
  },
  mounted() {
    // this.$refs.SearchDrawer.beforeMount(true);
    this.$E.$on("loadingpic", this.handleLoadingPic);
    this.$E.$on("loadingcamerapic", this.handleLoadingPic);
  },
  beforeDestroy() {
    this.$E.$off("loadingpic", this.handleLoadingPic);
    this.$E.$off("loadingcamerapic", this.handleLoadingPic);
  },
  computed: {
    previewSrcList() {
      return this.listData.map(({ ImageName }) =>
        this.handleImageSrc(ImageName)
      );
    },
  },
  methods: {
    sendWebrtcRequestNoIdentitySync(request, successCallback, failCallback) {
      this.sendWebrtcRequestNoIdentity(request)
        .then((resp) => successCallback?.(resp))
        .catch((error) => {
          failCallback?.(error);
        });
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        await this.Call(identity);
        var b = this.SendRequest(null, identity, request);
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    /**
     * 选择标签
     */
    handleSelTag(type, val) {
      if (type == 1) {
        if (this.selKeyWordTags.appDesTagSel.findIndex((m) => m == val) == -1) {
          this.selKeyWordTags.appDesTagSel.push(val);
        } else {
          this.selKeyWordTags.appDesTagSel =
            this.selKeyWordTags.appDesTagSel.filter((s) => s != val);
        }
      } else {
        if (
          this.selKeyWordTags.focusFormNameTagSel.findIndex((m) => m == val) ==
          -1
        ) {
          this.selKeyWordTags.focusFormNameTagSel.push(val);
        } else {
          this.selKeyWordTags.focusFormNameTagSel =
            this.selKeyWordTags.focusFormNameTagSel.filter((s) => s != val);
        }
      }
    },
    /**
     * 获取关键词标签
     */
    getKeyWordTag() {
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        datetype: this.dateType,
        processName: this.pnameText ? [this.pnameText] : [],
        softwareName: this.appDesText ? [this.appDesText] : [],
        formName: this.focusFormNameText ? [this.focusFormNameText] : [],
        page: 1,
        limit: 20,
      };
      if (this.isLocalStorage) {
        var self = this;
        var promiseSoftwareRank = new Promise((resolve, reject) => {
          var request = new Request();
          request.Url = "/Process/ProcessDetail";
          request.ContentType = "Json";
          const token = this.$xStorage.getItem("token");
          request.Headers.Authorization = token;
          request.Body = JSON.stringify({
            ...data,
            chartType: "softwareRank",
          });
          request.OnError = function (error) {
            reject(error);
          };
          request.OnResponse = function (response) {
            let result = response.Result;
            if (!result.State) {
              reject(result.Message);
            } else {
              resolve(result);
            }
          };
          this.sendWebrtcRequestNoIdentity(request).catch((error) => {
            reject(error);
          });
        });
        var promiseFormKeywordsRank = new Promise((resolve, reject) => {
          var request = new Request();
          request.Url = "/Process/ProcessDetail";
          request.ContentType = "Json";
          const token = this.$xStorage.getItem("token");
          request.Headers.Authorization = token;
          request.Body = JSON.stringify({
            ...data,
            chartType: "formKeywordsRank",
          });
          request.OnError = function (error) {
            reject(error);
          };
          request.OnResponse = function (response) {
            let result = response.Result;
            if (!result.State) {
              reject(result.Message);
            } else {
              resolve(result);
            }
          };
          this.sendWebrtcRequestNoIdentity(request).catch((error) => {
            reject(error);
          });
        });
        Promise.all([promiseSoftwareRank, promiseFormKeywordsRank]).then(
          (resp) => {
            if (resp[0].State) {
              self.keyWordTags.appDesTag = resp[0].Data.Datas;
            }
            if (resp[1].State) {
              self.keyWordTags.focusFormNameTag = resp[1].Data.Datas;
            }
          }
        );
      } else {
        Promise.all([
          this.$http.post("/Monitors/Details/Process.ashx", {
            ...data,
            chartType: "softwareRank",
          }),
          this.$http.post("/Monitors/Details/Process.ashx", {
            ...data,
            chartType: "formKeywordsRank",
          }),
        ]).then((resp) => {
          if (resp[0].res == 0) {
            this.keyWordTags.appDesTag = resp[0].data.Datas;
          }
          if (resp[1].res == 0) {
            this.keyWordTags.focusFormNameTag = resp[1].data.Datas;
          }
        });
      }
    },
    /**
     * 图表查看更多
     */
    handleChartMore(type) {
      this.moreChartOption.chartType = type;
      if (type == 1) {
        this.moreChartOption.type = 1;
        this.moreChartOption.title = "进程名关键词";
      } else if (type == 2) {
        this.moreChartOption.type = 2;
        this.moreChartOption.title = "窗口标题高频关键词";
      }
      this.$modal.show("screChartMore");
    },
    /**
     * 获取图表更多数据
     */
    getMoreChartData(page = 1, limit = 20, type) {
      this.$refs.chartMore.loadingChange(true);
      let chartType = "";
      if (type == 1) {
        chartType = "softwareRank";
      } else if (type == 2) {
        chartType = "formKeywordsRank";
      }
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        datetype: this.dateType,
        processName: this.pnameText ? [this.pnameText] : [],
        softwareName: this.appDesText ? [this.appDesText] : [],
        formName: this.focusFormNameText ? [this.focusFormNameText] : [],
        chartType: chartType, //titleKeywordsRank,browserTypesRank,siteRank
        page: page,
        limit: limit,
      };
      if (
        this.selKeyWordTags.appDesTagSel &&
        this.selKeyWordTags.appDesTagSel.length
      ) {
        data.softwareName = this.selKeyWordTags.appDesTagSel;
      }
      if (
        this.selKeyWordTags.focusFormNameTagSel &&
        this.selKeyWordTags.focusFormNameTagSel.length
      ) {
        data.formName = this.selKeyWordTags.focusFormNameTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/ProcessDetail";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function () {
          self.$refs.chartMore.loadingChange(false);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            if (type == 1) {
              result.Data.Datas.forEach((m) => {
                m.name = m.ProcessName;
              });
            } else if (type == 2) {
              result.Data.Datas.forEach((m) => {
                m.name = m.Keyword;
              });
            }
            self.moreChartOption.tData = result.Data.Datas;
            self.moreChartOption.dataTotal = result.Data.Total;
          }
          self.$refs.chartMore.loadingChange(false);
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          self.$refs.chartMore.loadingChange(false);
        });
      } else {
        this.$http
          .post("/Monitors/Details/Process.ashx", data)
          .then((resp) => {
            if (resp.res == 0) {
              if (type == 1) {
                resp.data.Datas.forEach((m) => {
                  m.name = m.ProcessName;
                });
              } else if (type == 2) {
                resp.data.Datas.forEach((m) => {
                  m.name = m.Keyword;
                });
              }
              this.moreChartOption.tData = resp.data.Datas;
              this.moreChartOption.dataTotal = resp.data.Total;
            }
          })
          .finally(() => this.$refs.chartMore.loadingChange(false));
      }
    },
    /**
     * 获取列表数据
     */
    async handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      this.HangUpAll();
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          this.$message({
            type: "error",
            message: error,
          });
          this.loading = false;
        }
      }
      this.queryData();
      this.getChartData();
      this.getKeyWordTag();
    },
    /**
     * 获取报表
     */
    async queryData() {
      this.loading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      {
        const _ = new Date(this.de);
        const date = _.getDate();
        _.setDate(date + 1);
        this.de = _.timeFormat("yyyy-MM-dd 23:59:59");
      }
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        page: this.pageData.pageIndex,
        limit: this.pageData.pageSize,
        teamId: this.teamValue,
        datetype: this.dateType,
        processName: this.pnameText ? [this.pnameText] : [],
        softwareName: this.appDesText ? [this.appDesText] : [],
        formName: this.focusFormNameText ? [this.focusFormNameText] : [],
      };
      if (
        this.selKeyWordTags.appDesTagSel &&
        this.selKeyWordTags.appDesTagSel.length
      ) {
        data.softwareName = this.selKeyWordTags.appDesTagSel;
      }
      if (
        this.selKeyWordTags.focusFormNameTagSel &&
        this.selKeyWordTags.focusFormNameTagSel.length
      ) {
        data.formName = this.selKeyWordTags.focusFormNameTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/Process";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          self.$message({
            type: "error",
            message: error,
          });
          self.loading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            self.$message({
              type: "error",
              message: result.Message,
            });
          } else {
            self.listData = result.Data.Datas;
            self.pageData.totalNum = result.Data.Total;
          }
          self.loading = false;
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
          this.loading = false;
        });
      } else {
        const { res, data: { Datas, Total } = {} } = await this.$http.post(
          "/Monitors/Process.ashx",
          data
        );
        if (res !== 0) return;

        this.listData = Datas;
        this.pageData.totalNum = Total;
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.handleGetData();
    },
    handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      this.handleGetData();
    },
    getChartData() {
      this.chartLoading = true;
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        datetype: this.dateType,
        processName: this.pnameText ? [this.pnameText] : [],
        softwareName: this.appDesText ? [this.appDesText] : [],
        formName: this.focusFormNameText ? [this.focusFormNameText] : [],
      };
      if (
        this.selKeyWordTags.appDesTagSel &&
        this.selKeyWordTags.appDesTagSel.length
      ) {
        data.softwareName = this.selKeyWordTags.appDesTagSel;
      }
      if (
        this.selKeyWordTags.focusFormNameTagSel &&
        this.selKeyWordTags.focusFormNameTagSel.length
      ) {
        data.formName = this.selKeyWordTags.focusFormNameTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/ProcessChart";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function () {
          self.chartLoading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            self.chartData = result.Data;
          }
          self.chartLoading = false;
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          this.chartLoading = false;
        });
      } else {
        this.$http
          .post("/Monitors/Charts/Process.ashx", data)
          .then((resp) => {
            if (resp.res == 0) {
              this.chartData = resp.data;
            }
          })
          .finally(() => (this.chartLoading = false));
      }
    },
    pickDate() {
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      // this.selMem = [];
    },
    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
    async handleScreenshotNow() {
      this.screenshotNowLoading = true;
      this.setTimer();
      await this.$http.get("/User/Work/NoticeUserScreenshots.ashx", {
        params: {
          Id: this.selMem[0].UsId,
          teamId: this.teamValue,
        },
      });
      // this.screenshotNowLoading = false
    },
    async handleCamerashotNow() {
      this.screenshotNowLoading = true;
      this.setTimer();
      await this.$http.get("/User/Work/NoticeUserPhotographs.ashx", {
        params: {
          Id: this.selMem[0].UsId,
          teamId: this.teamValue,
        },
      });
      // this.screenshotNowLoading = false
    },
    setTimer() {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.screenshotNowLoading) {
          this.screenshotNowLoading = false;
          this.$notify({
            type: "error",
            message: "请求超时",
          });
        }
      }, 1000 * 30);
    },
    handleLoadingPic(res) {
      console.log("收到截图");
      this.screenshotList = [];
      this.screenshotNowLoading = false;
      this.screenshotData = res;
      this.screenshotList = JSON.parse(res.imgUrl);
      this.dialogVisible = true;
    },
    handleElementClick(val, t = 1) {
      switch (t) {
        case 1:
          this.dialogs.software = {
            open: true,
            title: `进程名"${val}"`,
            options: {
              startTime: this.ds,
              endTime: this.de,
              users: this.selMem.map((m) => m.UsId),
              teamId: this.teamValue,
              datetype: this.dateType,
              processName: val ? [val] : [],
              softwareName: this.selKeyWordTags.appDesTagSel.length
                ? this.selKeyWordTags.appDesTagSel
                : this.appDesText
                ? [this.appDesText]
                : [],
              formName: this.selKeyWordTags.focusFormNameTagSel.length
                ? this.selKeyWordTags.focusFormNameTagSel
                : this.focusFormNameText
                ? [this.focusFormNameText]
                : [],
            },
          };
          break;
        case 2:
          this.dialogs.title = {
            open: true,
            title: `窗口标题"${val}"`,
            options: {
              startTime: this.ds,
              endTime: this.de,
              users: this.selMem.map((m) => m.UsId),
              teamId: this.teamValue,
              datetype: this.dateType,
              softwareName: this.selKeyWordTags.appDesTagSel.length
                ? this.selKeyWordTags.appDesTagSel
                : this.appDesText
                ? [this.appDesText]
                : [],
              formName: val ? [val] : [],
            },
          };
          break;
        default:
      }
    },
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
